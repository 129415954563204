import 'element-closest';
import $ from './nano';
import Lazyload from 'vanilla-lazyload'
import {loadScript} from './utils';

/**
 keep this, at least 1 Promise reference is necessary, because of webpack's dynamic import, we need a Promise polyfill
 but without any reference to Promise, babel fails to polyfill it
 https://github.com/babel/babel/issues/7795
 */
Promise;
Promise.all;

const body = $('body');

class LayoutClass {
	constructor() {
		this.initLazyLoad();
		this.initMenu();
		loadScript('https://instant.page/1.2.1');
	}

	initLazyLoad() {
		new Lazyload({
			'elements_selector': '.lazy'
		});
	}

	initMenu() {
		$('.HamburgerMenu-element').on('click', this.showHamburgerMenu.bind(this));
		$('.HeaderNavMobile-overlay, .HeaderNavMobile .close-btn').on('click', this.hideHamburgerMenu);

		window.addEventListener('popstate', () => {
			if (this.isHamburgerMenuVisible()) {
				this.hideHamburgerMenu();
			}
		});
	}

	isHamburgerMenuVisible() {
		return body.hasClass('has-visibleHeaderNavMobile');
	}

	showHamburgerMenu() {
		body.addClass('has-visibleHeaderNavMobile');
		history.pushState({}, document.title, '#menu');
	}

	hideHamburgerMenu() {
		body.removeClass('has-visibleHeaderNavMobile');

		if (location.hash === '#menu') {
			history.back();
		}
	}
}

export default new LayoutClass();